<style scoped>
    /* Set the page size to A4 and the orientation to landscape */
    @page {
        size: letter landscape;
    }

    /* Set the font size to 12px for printing */
    @media print {
        body {
            font-size: 12px;
        }
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 20px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    #no-border {
        border: none;
    }

    td, th.bordered {
        /* border: 1px solid #000000; */
        border-top: 1px solid #000000;
        border-bottom: 1px solid #000000;
        border-right: 1px solid #000000;
        border-left: 1px solid #000000;
        text-align: left;
        padding: 8px;
    }

    td.no-border-flat {
        border-top: none;
        border-bottom: none;
    }

    th.no-border {
        border: none;
    }

    .remove_border tr th{
        border: none;
    }

    #table-list {
        margin-top: 80px;
    }

    .info {
        margin-top: 20px;
        width: 40%;
        float: left;
        margin-bottom: 20px;
    }
</style>

<template>
    <div>
        <div class="flex w-full">
            <img :src="logo" height="55" class="mr-5">
            <div class="title text-left">
                <div>
                    <strong>SDN-FRM-LOG.05-008</strong>
                </div>
                <div>
                    <strong>BERITA ACARA PERUBAHAN MATERIAL</strong>
                </div>
            </div>
        </div>
        <table>
            <thead>
                <tr>
                    <th class="py-0" colspan="3">Kode Cabang</th>
                    <th class="py-0">:</th>
                    <th class="py-0" colspan="13">{{ warehouse.code }}</th>
                    <th class="py-0">Nomor</th>
                    <th class="py-0">:</th>
                    <th class="py-0" colspan="2">{{ code }}</th>
                </tr>
                <tr>
                    <th class="py-0" colspan="3">Cabang</th>
                    <th class="py-0">:</th>
                    <th class="py-0" colspan="13">{{ warehouse.name }}</th>
                    <th class="py-0">Tanggal</th>
                    <th class="py-0">:</th>
                    <th class="py-0" colspan="2">{{ date }}</th>
                </tr>
                <tr>
                    <th class="py-2" colspan="21">Dengan ini kami sampaikan pengajuan "Berita Acara Perubahan Material" dengan detail sebagai berikut:</th>
                </tr>
                <tr>
                    <th class="py-1 bordered text-center" colspan="10">Asal</th>
                    <th class="py-1 bordered text-center" colspan="10">Tujuan</th>
                    <th class="py-1 bordered text-center align-middle" rowspan="2">Keterangan</th>
                </tr>
                <tr>
                    <th class="py-1 bordered text-center align-middle">No</th>
                    <th class="py-1 bordered text-center align-middle">Type</th>
                    <th class="py-1 bordered text-center align-middle">Kode</th>
                    <th class="py-1 bordered text-center align-middle">Nama Produk</th>
                    <th class="py-1 bordered text-center align-middle">Principal</th>
                    <th class="py-1 bordered text-center align-middle">Qty</th>
                    <th class="py-1 bordered text-center align-middle">Unit</th>
                    <th class="py-1 bordered text-center align-middle">Stor.Loc.</th>
                    <th class="py-1 bordered text-center align-middle">Nomor Batch</th>
                    <th class="py-1 bordered text-center align-middle">Tanggal Expired</th>
                    <th class="py-1 bordered text-center align-middle">No</th>
                    <th class="py-1 bordered text-center align-middle">Type</th>
                    <th class="py-1 bordered text-center align-middle">Kode</th>
                    <th class="py-1 bordered text-center align-middle">Nama Produk</th>
                    <th class="py-1 bordered text-center align-middle">Principal</th>
                    <th class="py-1 bordered text-center align-middle">Qty</th>
                    <th class="py-1 bordered text-center align-middle">Unit</th>
                    <th class="py-1 bordered text-center align-middle">Stor.Loc.</th>
                    <th class="py-1 bordered text-center align-middle">Nomor Batch</th>
                    <th class="py-1 bordered text-center align-middle">Tanggal Expired</th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="(td, idx) in skuLines" :key="idx">
                    <td class="py-1 px-2">{{ idx + 1 }}</td>
                    <td class="py-1 px-2">{{ docType }}</td>
                    <td class="py-1 px-2">{{ td.item_code }}</td>
                    <td class="py-1 px-2">{{ td.item_name }}</td>
                    <td class="py-1 px-2">Principal</td>
                    <td class="py-1 px-2">{{ td.qty }}</td>
                    <td class="py-1 px-2">{{ td.unit }}</td>
                    <td class="py-1 px-2">{{ srcWarehouseArea.code }}</td>
                    <td class="py-1 px-2">{{ td.batch_only }}</td>
                    <td class="py-1 px-2">{{ td.exp_date }}</td>

                    <td class="py-1 px-2">{{ idx + 1 }}</td>
                    <td class="py-1 px-2">{{ docType }}</td>
                    <td class="py-1 px-2">{{ td.item_code }}</td>
                    <td class="py-1 px-2">{{ td.item_name }}</td>
                    <td class="py-1 px-2">Principal</td>
                    <td class="py-1 px-2">{{ td.qty }}</td>
                    <td class="py-1 px-2">{{ td.unit }}</td>
                    <td class="py-1 px-2">{{ destWarehouseArea.code }}</td>
                    <td class="py-1 px-2">{{ td.batch_only }}</td>
                    <td class="py-1 px-2">{{ td.exp_date }}</td>

                    <td class="py-1 px-2"></td>
                </tr>
                <tr>
                    <td class="py-1 text-center" colspan="5">Total (UOM)</td>
                    <td>{{ totalQty }}</td>
                    <td colspan="4"></td>
                    <td class="py-1 text-center" colspan="5">Total (UOM)</td>
                    <td>{{ totalQty }}</td>
                    <td colspan="4"></td>
                    <td class="py-1"></td>
                </tr>
            </tbody>
        </table>
        <div class="py-2">
            Note: <strong>Wajib</strong> menyertakan foto dokumentasi produk
        </div>
        <table class="w-1/3">
            <thead>
                <tr>
                    <th class="bordered text-center">Dibuat Oleh,</th>
                    <th class="bordered text-center">Disetujui Oleh,</th>
                    <th class="bordered text-center" colspan="3">Diketahui Oleh,</th>
                </tr>
            </thead>
            <tbody>
                <!-- <tr>
                    <td class="w-1/5 px-1 text-center">
                        Admin Gudang
                        <br/>
                        <br/>
                        <br/>
                        (. . . . . . . . . .)
                    </td>
                    <td class="w-1/5 px-1 text-center">
                        WHS Supervisor
                        <br/>
                        <br/>
                        <br/>
                        (. . . . . . . . . .)
                    </td>
                    <td class="w-1/5 px-1 text-center">
                        BLC
                        <br/>
                        <br/>
                        <br/>
                        (. . . . . . . . . .)
                    </td>
                    <td class="w-1/5 px-1 text-center">
                        BAM/BAS/OS
                        <br/>
                        <br/>
                        <br/>
                        (. . . . . . . . . .)
                    </td>
                    <td class="w-1/5 px-1 text-center">
                        BM/OM
                        <br/>
                        <br/>
                        <br/>
                        (. . . . . . . . . .)
                    </td>
                </tr> -->
                <tr>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        Admin Gudang
                    </td>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        WHS Supervisor
                    </td>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        BLC
                    </td>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        BAM/BAS/OS
                    </td>
                    <td class="w-1/5 px-1 text-center no-border-flat">
                        BM/OM
                    </td>
                </tr>
                <tr>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . .)</td>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . .)</td>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . .)</td>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . .)</td>
                    <td class="w-1/5 px-1 py-3 no-border-flat text-center">(. . . . . . . . . .)</td>
                </tr>
                <tr>
                    <td class="px-1 py-1">Tanggal:</td>
                    <td class="px-1 py-1">Tanggal:</td>
                    <td class="px-1 py-1">Tanggal:</td>
                    <td class="px-1 py-1">Tanggal:</td>
                    <td class="px-1 py-1">Tanggal:</td>
                </tr>
            </tbody>
        </table>
    </div>
</template>
<script>
import logo from "@/assets/images/logo/logo-sdn.png"
import moment from "moment"
export default {
    mounted() {
        this.id = this.$route.params.id
        if (this.id) {
            this.print()
        }
    },
    data() {
        return {
            id: null,
            logo: logo,
            code: null,
            date: null,
            warehouse: null,
            srcWarehouseArea: null,
            destWarehouseArea: null,
            docType: "",
            skuLines: [
                {
                    line_id: 0,
                    sku_code: null,
                    item_code: null,
                    item_name: null,
                    batch: null,
                    batch_only: null,
                    exp_date: null,
                    qty: null,
                    unit: null,
                    qty_uom: 0,
                }
            ],
            rawTotalQty: 0,
            totalQty: null,
        }
    },
    methods: {
        async print() {
            await this.getData()
            await window.print()
        },
        async getData() {
            this.$vs.loading();
            const resp = await this.$http.get("api/wms/v1/inter-storage/detail/"+this.id)
            if (resp.code == 200) {
                this.id = resp.data.id
                this.code = resp.data.code
                this.date = resp.data.date
                this.warehouse = resp.data.warehouse
                this.srcWarehouseArea = resp.data.from_warehouse_area
                this.destWarehouseArea = resp.data.to_warehouse_area
                this.docType = resp.data.type
                this.skuLines = resp.data.sku_lines;
                this.skuLines.forEach(val => {
                    this.rawTotalQty = this.rawTotalQty + (val.qty * val.qty_uom)
                    val.qty = this.handleUpdateFormat(val.qty)
                })
                this.totalQty = this.handleUpdateFormat(this.rawTotalQty)
            }
            this.$vs.loading.close();
        },
        handleUpdateFormat(value) {
            let cleanValue = value.toString().replace(/\D/g, '');
            return new Intl.NumberFormat('id-ID').format(cleanValue);
        },
    },
    computed: {
        formatDate: function () {
            return (val) => moment(val).format("YYYY-MM-DD")
        }
    },
}
</script>